import React from "react";
import { Icon, Position } from "@blueprintjs/core";
import { Popover2 } from "@blueprintjs/popover2";
import zxcvbn from "ts-zxcvbn";

import "./PasswordStrengthMeter.scss";

export type PasswordStrengthMeterProps = {
  password: string;
  style?: React.CSSProperties;
  feedback?: (score: number, suggestions: string[]) => void;
};

export type PasswordStrengthFeedBack = {
  score: number;
  warning: string | null;
  suggestions: string[];
};

export const PasswordStrengthMeter = (props: PasswordStrengthMeterProps) => {
  const result = zxcvbn(props.password);
  const full = props.password.length === 0 ? 0 : result.score + 1;
  if (props.feedback) {
    props.feedback(full, result.feedback.suggestions);
  }
  return (
    <div>
      <Popover2
        isOpen={
          props.password.length > 4 && result.feedback.suggestions.length > 0
        }
        position={Position.BOTTOM_RIGHT}
      >
        <div className="password-strength-meter" style={props.style}>
          {new Array(full).fill(
            <>
              <Icon icon="star" />
              {"\xa0"}
            </>
          )}
          {new Array(5 - full).fill(
            <>
              <Icon icon="star-empty" />
              {"\xa0"}
            </>
          )}
        </div>

        <div className="suggestions-popover">
          <h3>Password Suggestions</h3>
          <ul>
            {result.feedback.suggestions.map((value) => (
              <li>{value}</li>
            ))}
          </ul>
        </div>
      </Popover2>

      {result.feedback.warning !== null && (
        <span className="password-warning">
          {result.feedback.warning.replace(/([A-Z])/g, " $1")}
        </span>
      )}
    </div>
  );
};
