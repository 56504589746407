export type DetailEntryProps = {
  name: string;
  value: any;
};

export const DetailEntry = (props: DetailEntryProps) => (
  <tr>
    <td style={{ textAlign: "right" }}>
      <b>{props.name}</b>
    </td>
    <td>
      <pre>{props.value}</pre>
    </td>
  </tr>
);
