import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { GetTenantResponse } from "@microsearch/g4api-support";

import { session, showError } from "../..";
import { TenantPageLayout } from "../TenantPageLayout/TenantPageLayout";
import { ProfilesList } from "../../components/ProfilesList";

type G4Tenant = GetTenantResponse;

export const TenantProfilesPage = () => {
  const { tid } = useParams<{ tid: string }>();
  const [tenant, setTenant] = useState<G4Tenant | null>(null);

  useEffect(() => {
    (async () => {
      try {
        setTenant((await session.tenant.get(+(tid as string))).data);
      } catch (error) {
        if (error instanceof Error) showError(error.message);
      }
    })();
  }, [tid]);

  return (
    <>
      {tenant && (
        <TenantPageLayout tenant={tenant} selector="profiles">
          <ProfilesList tenant={tenant} />
        </TenantPageLayout>
      )}
    </>
  );
};
