import React from "react";
import { useNavigate } from "react-router-dom";
import { Menu, MenuItem } from "@blueprintjs/core";

import "./AdminsPageLayout.scss";
import { session } from "../..";
import { PageBanner } from "../../components/PageBanner";
import { StatusBar } from "../../components/StatusBar";

type AdminPageSelector = "admins" | "admin-events" | "roles";

export type AdminsPageLayoutProps = {
  selector: AdminPageSelector;
  children: React.ReactNode;
};

export const AdminsPageLayout = (props: AdminsPageLayoutProps) => {
  const navigate = useNavigate();

  return (
    <>
      <div className="admins-page">
        <div className="banner">
          <PageBanner session={session} />
        </div>
        <div className="title">
          <h1>MicroSearch Administrators</h1>
        </div>
        <div className="sidebar">
          <Menu className="sidebar-menu">
            <MenuItem
              icon="people"
              text="Admin Manager"
              disabled={props.selector === "admins"}
              onClick={() => navigate(`/admins`)}
            />
            <MenuItem
              icon="timeline-events"
              text="Admin Event Log"
              disabled={props.selector === "admin-events"}
              onClick={() => navigate(`/admin-events`)}
            />
            <MenuItem
              icon="badge"
              text="Admin Roles"
              disabled={props.selector === "roles"}
              onClick={() => navigate(`/admin-roles`)}
            />
          </Menu>
        </div>

        <div className="main">{props.children}</div>

        <div className="status">
          <StatusBar />
        </div>
      </div>
    </>
  );
};
