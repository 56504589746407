import React from "react";
import ProtectedRoute from "./components/ProtectedRoute";
import { session } from ".";

export type G4RouteProps = {
  outlet: JSX.Element;
};

export const G4Route = (props: G4RouteProps) => (
  <ProtectedRoute
    isAuthenticated={session.connected()}
    authenticationPath="/login"
    outlet={props.outlet}
  />
);
