import React, { useState } from "react";
import {
  Navbar,
  Alignment,
  Button,
  Drawer,
  DrawerSize,
  Intent,
  HTMLTable,
  Card,
} from "@blueprintjs/core";
import { useNavigate } from "react-router-dom";
import { G4BrowserSession } from "@microsearch/g4api-browser";
import { GetTenantResponse } from "@microsearch/g4api-support";

import { stage } from "../version";
import { PasswordChangeDialog } from "./PasswordChangeDialog";

const MIN_PASSWORD_STRENGTH = stage === "prod" ? 5 : 3;

type G4Tenant = GetTenantResponse;

export type PageBannerProps = {
  session?: G4BrowserSession;
  tenant?: G4Tenant;
};

export const PageBanner = (props: PageBannerProps) => {
  const [passwordDialog, setPasswordDialog] = useState(false);
  const [showUser, setShowUser] = useState(false);
  const navigate = useNavigate();

  return (
    <>
      <Navbar className="bp4-dark">
        <Navbar.Group align={Alignment.LEFT}>
          <Navbar.Heading>
            <b>G4 Administration</b>
            {` (${stage})`}
          </Navbar.Heading>
          {props.session && (
            <>
              <Navbar.Divider />
              <Button
                className="bp4-minimal"
                title="G4 Tenants"
                icon="home"
                text="Home"
                onClick={() => navigate("/")}
              />
            </>
          )}
        </Navbar.Group>
        {props.session && (
          <Navbar.Group align={Alignment.RIGHT}>
            <Button
              text={props.session.username}
              title="Your Account"
              onClick={() => {
                setShowUser(true);
              }}
            />
            <Button
              rightIcon="log-out"
              title="Log Out"
              onClick={() => {
                props.session!.disconnect();
                navigate("/");
              }}
            />
          </Navbar.Group>
        )}
      </Navbar>

      <PasswordChangeDialog
        isOpen={passwordDialog}
        minScore={MIN_PASSWORD_STRENGTH}
        close={() => {
          setPasswordDialog(false);
        }}
      />

      {props.session && (
        <Drawer
          isOpen={showUser}
          title={props.session.username}
          size={DrawerSize.SMALL}
          isCloseButtonShown={true}
          autoFocus={true}
          onClose={() => setShowUser(false)}
        >
          <Button
            text="Change Your Password"
            intent={Intent.PRIMARY}
            onClick={() => setPasswordDialog(true)}
            style={{ margin: "4mm" }}
          />
          <div style={{ overflow: "auto" }}>
            <Card>
              <HTMLTable
                className="data-table"
                condensed
                striped
                style={{ width: "100%" }}
              >
                <thead>
                  <tr>
                    <td>Account Claims</td>
                  </tr>
                </thead>
                <tbody>
                  {props.session.claims.map((claim, index) => (
                    <tr key={index}>
                      <td>
                        <pre>{claim}</pre>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </HTMLTable>
            </Card>
          </div>
        </Drawer>
      )}
    </>
  );
};
