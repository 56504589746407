import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Alert, Button, Intent, Menu, MenuItem } from "@blueprintjs/core";
import { getG4ApiError, GetTenantResponse } from "@microsearch/g4api-support";

import { session, showError } from "../..";
import "./TenantPageLayout.scss";
import { PageBanner } from "../../components/PageBanner";
import { StatusBar } from "../../components/StatusBar";

type G4Tenant = GetTenantResponse;

type TenantPageSelector =
  | "dashboard"
  | "users"
  | "user-events"
  | "roles"
  | "profiles";

export type TenantPageLayoutProps = {
  tenant: G4Tenant;
  selector: TenantPageSelector;
  children: React.ReactNode;
};

export const TenantPageLayout = (props: TenantPageLayoutProps) => {
  const navigate = useNavigate();
  const [archiveAlertIsOpen, setArchiveAlertIsOpen] = useState(false);

  async function archive() {
    try {
      await session.tenant.delete(props.tenant.id);
      navigate("/");
    } catch (error: unknown) {
      showError(getG4ApiError(error).message);
    }
  }

  return (
    <>
      <div className="tenant-page">
        <div className="banner">
          <PageBanner session={session} tenant={props.tenant} />
        </div>
        <div className="tenant">
          <h1>{props.tenant.name}</h1>
          {session.claims.includes("g4admin:DEL /tenant/{id}") && (
            <Button
              onClick={() => setArchiveAlertIsOpen(true)}
              intent={Intent.DANGER}
            >
              Archive {props.tenant.name}
            </Button>
          )}
          <h3>{props.tenant.description}</h3>
        </div>
        <div className="sidebar">
          <Menu className="sidebar-menu">
            <MenuItem
              icon="dashboard"
              text="Tenant Dashboard"
              disabled={props.selector === "dashboard"}
              onClick={() => navigate(`/tenant/dashboard/${props.tenant.id}`)}
            />
            <MenuItem
              icon="people"
              text="User Manager"
              disabled={props.selector === "users"}
              onClick={() => navigate(`/tenant/users/${props.tenant.id}`)}
            />
            <MenuItem
              icon="timeline-events"
              text="User Event Log"
              disabled={props.selector === "user-events"}
              onClick={() => navigate(`/tenant/user-events/${props.tenant.id}`)}
            />
            <MenuItem
              icon="badge"
              text="Roles"
              disabled={props.selector === "roles"}
              onClick={() => navigate(`/tenant/roles/${props.tenant.id}`)}
            />
            <MenuItem
              icon="mugshot"
              text="Profiles"
              disabled={props.selector === "profiles"}
              onClick={() => navigate(`/tenant/profiles/${props.tenant.id}`)}
            />
          </Menu>
        </div>

        <div className="main">{props.children}</div>

        <div className="status">
          <StatusBar />
        </div>

        <Alert
          isOpen={archiveAlertIsOpen}
          cancelButtonText="Cancel"
          confirmButtonText={`Archive ${props.tenant.name}`}
          icon="archive"
          intent={Intent.DANGER}
          canEscapeKeyCancel={true}
          canOutsideClickCancel={true}
          onCancel={() => setArchiveAlertIsOpen(false)}
          onConfirm={archive}
        >
          <p>Are you sure?</p>
          <p>
            Archived tenants may be recovered until they are eventually purged
            from the system.
          </p>
        </Alert>
      </div>
    </>
  );
};
