import { useState } from "react";
import { Button, Dialog, FormGroup, Intent, Classes } from "@blueprintjs/core";
import { useForm } from "react-hook-form";

import "./PasswordChangeDialog.scss";
import { session, showError, showSuccess, showWarning } from "..";
import { PasswordStrengthMeter } from "./PasswordStrengthMeter";

export type PasswordChangeDialogProps = {
  isOpen?: boolean;
  close: () => void;
  minScore: number;
};

export const PasswordChangeDialog = (props: PasswordChangeDialogProps) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const [password, setPassword] = useState("");
  const [score, setScore] = useState(0);

  async function onSubmit(data: Record<string, string>) {
    try {
      const current_password = data["current_password"];
      const new_password = data["new_password"];
      const verify_password = data["verify_password"];
      if (current_password === new_password) {
        showWarning("Password has not changed");
      } else if (new_password === verify_password) {
        await session.password.put({
          username: session.username!,
          password: current_password,
          newPassword: new_password,
        });
        showSuccess("Password successfully changed");
        props.close();
      } else {
        showWarning("Passwords do not match");
      }
    } catch (error) {
      if (error instanceof Error) {
        showError(error.message);
      }
    }
  }

  return (
    <Dialog
      className="password-change-dialog"
      title="Change Your Password"
      isOpen={props.isOpen}
      canEscapeKeyClose={true}
      canOutsideClickClose={true}
      onClose={props.close}
    >
      <div className={Classes.DIALOG_BODY}>
        <form onSubmit={handleSubmit(onSubmit)}>
          <FormGroup
            className="password-change-field"
            label="Current Password:"
            labelFor="current_password"
            inline={true}
          >
            <input
              {...register("current_password", { required: true })}
              className="bp4-input"
              id="current_password"
              autoFocus
              type="password"
            />
            {errors.current_password && (
              <p className="validation-error"> required</p>
            )}
          </FormGroup>
          <FormGroup
            className="password-change-field"
            label="New Password:"
            labelFor="new_password"
            inline={true}
          >
            <input
              {...register("new_password", { required: true })}
              className="bp4-input"
              id="new_password"
              type="password"
              onChange={(event) => {
                setPassword(event.target.value);
              }}
            />
            {errors.new_password && (
              <p className="validation-error"> required</p>
            )}
          </FormGroup>
          <FormGroup
            className="password-change-field"
            label="Verify Password:"
            labelFor="verify_password"
            inline={true}
          >
            <input
              {...register("verify_password", { required: true })}
              className="bp4-input"
              id="verify_password"
              type="password"
            />
            {errors.verify_password && (
              <p className="validation-error"> required</p>
            )}
          </FormGroup>
          <FormGroup
            className="password-change-field"
            label="New Password Strength:"
            labelFor="password_strength"
            inline={true}
          >
            <PasswordStrengthMeter
              password={password}
              style={{ color: "goldenrod" }}
              feedback={(score, _suggestions) => {
                setScore(score);
              }}
            />
          </FormGroup>
          <div className={Classes.DIALOG_FOOTER}>
            <div className={Classes.DIALOG_FOOTER_ACTIONS}>
              <Button
                intent={Intent.PRIMARY}
                type="submit"
                disabled={score < props.minScore}
              >
                Change Password
              </Button>
            </div>
          </div>
        </form>
      </div>
    </Dialog>
  );
};
