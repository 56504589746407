import React, { useEffect, useState } from "react";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { HTMLTable, Dialog } from "@blueprintjs/core";
import { AdminUser } from "@microsearch/g4api-support";
import { G4UserStatus } from "@microsearch/g4api-browser";

import "./AdminsPage.scss";
import { session, showError } from "../..";
import { DetailEntry } from "../../components/DetailEntry";
import { AdminsPageLayout } from "../AdminsPageLayout/AdminsPageLayout";

type G4Admin = AdminUser;

export const AdminsPage = () => {
  const [selectedAdmin, setSelectedAdmin] = useState<G4Admin | null>(null);
  const [admins, setAdmins] = useState<G4Admin[]>([]);

  useEffect(() => {
    (async () => {
      try {
        setAdmins(
          (await session.admins.get()).data.admins!.sort((a, b) =>
            a.fullname.localeCompare(b.fullname)
          )
        );
      } catch (error) {
        if (error instanceof Error) {
          showError(error.message);
        }
      }
    })();
  }, []);

  return (
    <AdminsPageLayout selector="admins">
      <AdminHeader />
      <AutoSizer>
        {(size) => (
          <FixedSizeList
            className="admins"
            height={size.height - 25}
            width={size.width}
            itemCount={admins.length}
            itemSize={25}
          >
            {({ index, style }) => {
              const admin = admins[index];
              return (
                <AdminItem
                  index={index}
                  style={style}
                  admin={admin}
                  click={() => {
                    setSelectedAdmin(admin);
                  }}
                />
              );
            }}
          </FixedSizeList>
        )}
      </AutoSizer>

      <Dialog
        isOpen={selectedAdmin !== null}
        icon="person"
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        title={selectedAdmin?.fullname ?? ""}
        onClose={() => setSelectedAdmin(null)}
        style={{
          overflow: "auto",
          whiteSpace: "nowrap",
          width: "50vw",
        }}
      >
        {selectedAdmin && <AdminDetails admin={selectedAdmin} />}
      </Dialog>
    </AdminsPageLayout>
  );
};

const AdminHeader = () => (
  <div className="admin-header">
    <div className="admin-index" />
    <div className="admin-fullname">Administrator</div>
    <div className="admin-username">Username</div>
    <div className="admin-email">Email</div>
    <div className="admin-status">Status</div>
    <div className="admin-lastseen">Last Login</div>
  </div>
);

type AdminItemProps = {
  index: number;
  admin: G4Admin;
  style?: React.CSSProperties;
  click: () => void;
};

const AdminItem = (props: AdminItemProps) => (
  <div className="admin-row" style={props.style} onClick={() => props.click()}>
    <div className="admin-index">{props.index.toLocaleString()}</div>
    <div className="admin-fullname">{props.admin.fullname}</div>
    <div className="admin-username">{props.admin.username}</div>
    <div className="admin-email">{props.admin.email}</div>
    <div className="admin-status">{G4UserStatus[props.admin.status]}</div>
    <div className="admin-lastseen">
      {props.admin.lastSeen
        ? new Date(props.admin.lastSeen).toLocaleDateString()
        : ""}
    </div>
  </div>
);

const AdminDetails = (props: { admin: G4Admin }) => (
  <HTMLTable bordered={true} condensed={true}>
    <tbody>
      <DetailEntry name="Username" value={props.admin.username} />
      <DetailEntry name="Status" value={G4UserStatus[props.admin.status]} />
      <DetailEntry name="Email" value={props.admin.email} />
      <DetailEntry
        name="Roles"
        value={props.admin.roleNames.sort().join("\n")}
      />
    </tbody>
  </HTMLTable>
);
