import { session } from "..";
import { version } from "../version";
import "./StatusBar.scss";

export const StatusBar = () => {
  return (
    <div className="status-bar">
      <div>
        G4 API endpoint: <b>{session.endpoint}</b>
      </div>
      <div>{version}</div>
    </div>
  );
};
