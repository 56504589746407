import { Button, Dialog, HTMLTable, IconName, Intent } from "@blueprintjs/core";
import React, { useState } from "react";
import { GetTenantResponse, RoleResponse } from "@microsearch/g4api-support";
import RoleForm, { RoleFormValues } from "./RoleForm";
import { session, showError } from "../index";

type G4Tenant = GetTenantResponse;
type G4Role = RoleResponse;

type useRolesButtonProps = {
  roles: G4Role[];
  role?: G4Role;
};

type useRolesButtonType = {
  onClick: () => void;
  modifyingRole: boolean;
  setModifyingRole: (modifyingRole: boolean) => void;
  selectedClaims: string[];
  setSelectedClaims: (selectedClaims: string[]) => void;
  onClose: () => void;
  icon: IconName;
  claimList: string[];
  scopeList: string[];
  canEscapeKeyClose: boolean;
  isCloseButtonShown: boolean;
  canOutsideClickClose: boolean;
};

type RolesAddButtonProps = {
  tenant: G4Tenant;
  roles: G4Role[];
  setRoles: (roles: G4Role[]) => void;
  canUse: boolean;
};

interface RolesEditButtonProps extends RolesAddButtonProps {
  role: G4Role;
}

export const useRolesButton = ({
  roles,
  role,
}: useRolesButtonProps): useRolesButtonType => {
  const [modifyingRole, setModifyingRole] = useState<boolean>(false);
  const [selectedClaims, setSelectedClaims] = useState<string[]>(
    role ? role.claims : []
  );
  const onClick = () => setModifyingRole(true);

  const dialogProps = {
    icon: "badge" as IconName,
    canEscapeKeyClose: true,
    isCloseButtonShown: true,
    canOutsideClickClose: true,
    onClose: () => setModifyingRole(false),
  };

  const getAllClaims = (roles: G4Role[]): string[] => {
    let claimList: string[] = [];
    roles.forEach((role) => {
      claimList = [
        ...claimList,
        ...role.claims.filter((claim) => !claimList.includes(claim)),
      ];
    });
    return claimList.sort((a, b) => {
      const [scope1, claim1] = a.split(":");
      const [scope2, claim2] = b.split(":");
      const sc = scope1.localeCompare(scope2);
      return sc !== 0 ? sc : claim1.localeCompare(claim2);
    });
  };

  const claimList = getAllClaims(roles);

  const getAllScopes = (): string[] => {
    let scopes = claimList
      .map((claim) => claim.split(":")[0])
      .concat(roles.map((thisRole) => thisRole.scope));
    return scopes
      .filter((v, i) => !scopes.includes(v, i + 1))
      .filter((scope) => scope !== "g4admin");
  };

  return {
    onClick,
    modifyingRole,
    setModifyingRole,
    selectedClaims,
    setSelectedClaims,
    claimList,
    scopeList: getAllScopes(),
    ...dialogProps,
  };
};

const RolesEditButton = ({
  tenant,
  roles,
  setRoles,
  canUse,
  role,
}: RolesEditButtonProps) => {
  const {
    onClick,
    modifyingRole,
    setModifyingRole,
    selectedClaims,
    setSelectedClaims,
    claimList,
    scopeList,
    ...dialogProps
  } = useRolesButton({ roles, role });

  const updateRole = async (data: RoleFormValues): Promise<G4Role> => {
    const { roleName, claims, ...restData } = data;
    const updateData = {
      newName: roleName,
      claims: selectedClaims,
      ...restData,
    };
    const response = await session
      .clone({ tenant: tenant.name })
      .role.put((role as G4Role).id, updateData);
    return response.data;
  };

  const defaultValues = {
    roleName: role.name,
    defaultScope: role.scope,
    claims: claimList.map((claim) => ({
      name: claim,
      scope: claim.split(":")[0],
    })),
  };

  const formProps = {
    sendRoleData: updateRole,
    selectedClaims,
    setModifyingRole,
    setRoles,
    roles,
    setSelectedClaims,
    scopeList,
    claimList,
    defaultValues,
  };

  const buttonProps = {
    icon: "edit" as IconName,
    text: "",
    title: `Edit Role for ${tenant.name}`,
    ...(canUse ? { onClick } : { disabled: true }),
  };

  return (
    <>
      <Button className="bp4-minimal" onClick={onClick} {...buttonProps} />
      <Dialog
        {...dialogProps}
        isOpen={modifyingRole}
        title={`${tenant.name}`}
        style={{ overflow: "auto", whiteSpace: "nowrap", width: "25vw" }}
      >
        {modifyingRole && <RoleForm {...formProps} role={role} />}
      </Dialog>
    </>
  );
};

export const RolesAddButton = ({
  tenant,
  roles,
  setRoles,
  canUse,
}: RolesAddButtonProps) => {
  const {
    selectedClaims,
    setModifyingRole,
    onClick,
    modifyingRole,
    setSelectedClaims,
    scopeList,
    claimList,
    ...dialogProps
  } = useRolesButton({ roles });

  const sendNewRole = async (data: RoleFormValues): Promise<G4Role> => {
    const { roleName, claims, defaultScope, ...restData } = data;
    const newRoleData = {
      name: roleName,
      defaultScope,
      claims: selectedClaims,
      ...restData,
    };
    const response = await session
      .clone({ tenant: tenant.name })
      .role.post(newRoleData);
    return {
      id: response.data.id,
      name: roleName,
      scope: defaultScope,
      claims: selectedClaims,
    };
  };

  const defaultValues = {
    roleName: "placeholder",
    defaultScope: scopeList.filter((scope) => scope !== "g4")[0],
    claims: claimList.map((claim) => ({
      name: claim,
      scope: claim.split(":")[0],
    })),
  };

  const formProps = {
    selectedClaims,
    sendRoleData: sendNewRole,
    setModifyingRole,
    setRoles,
    roles,
    setSelectedClaims,
    claimList,
    scopeList,
    defaultValues,
  };

  const buttonProps = {
    icon: "add" as IconName,
    text: "Add Role",
    title: `Add Role for ${tenant.name}`,
    ...(canUse ? { onClick } : { disabled: true }),
  };

  return (
    <>
      <Button className="bp4-minimal" {...buttonProps} />
      <Dialog
        {...dialogProps}
        isOpen={modifyingRole}
        title={`${tenant.name}`}
        style={{ overflow: "auto", whiteSpace: "nowrap", width: "25vw" }}
      >
        {modifyingRole && <RoleForm {...formProps} />}
      </Dialog>
    </>
  );
};

type RolesViewTableProps = {
  selectedRole: G4Role;
};

export const RolesViewTable = ({ selectedRole }: RolesViewTableProps) => {
  return (
    <HTMLTable bordered={true} condensed={true}>
      <tbody>
        <tr>
          <td>Id</td>
          <td>{selectedRole.id}</td>
        </tr>
        <tr>
          <td>Scope</td>
          <td>{selectedRole.scope}</td>
        </tr>
        <tr>
          <td>Role</td>
          <td>{selectedRole.name}</td>
        </tr>
        <tr>
          <td>Claims</td>
          <td>
            <pre>{selectedRole.claims.sort().map((claim) => `${claim}\n`)}</pre>
          </td>
        </tr>
      </tbody>
    </HTMLTable>
  );
};

interface RolesDeleteButtonProps extends RolesAddButtonProps {
  role: G4Role;
}

export const RolesDeleteButton = ({
  tenant,
  roles,
  setRoles,
  role,
  canUse,
}: RolesDeleteButtonProps) => {
  const [deletingRole, setDeletingRole] = useState<boolean>(false);
  const onDeleteRolePromptClick = () => {
    setDeletingRole(true);
  };

  const buttonProps = {
    icon: "trash" as IconName,
    title: `Delete Role for ${tenant.name}`,
    ...(canUse ? { onClick: onDeleteRolePromptClick } : { disabled: true }),
  };

  const onDeleteRoleSubmit = () => {
    session
      .clone({ tenant: tenant.name })
      .role.delete(role.id)
      .then((_response) => {
        setRoles(roles.filter((thisRole) => thisRole.id !== role.id));
        setDeletingRole(false);
      })
      .catch((error) => {
        if (error instanceof Error) showError(error.message);
      });
  };

  return (
    <>
      <Button className="bp4-minimal" {...buttonProps} />
      <Dialog
        isOpen={deletingRole}
        isCloseButtonShown={true}
        icon="badge"
        canEscapeKeyClose={true}
        canOutsideClickClose={true}
        onClose={() => setDeletingRole(false)}
        title={`${tenant.name}`}
        style={{ overflow: "auto", whiteSpace: "nowrap", width: "25vw" }}
      >
        {deletingRole && (
          <div>
            <RolesViewTable selectedRole={role} />
            <Button
              type={"button"}
              icon={"trash"}
              className={"bp4-button"}
              intent={Intent.DANGER}
              onClick={onDeleteRoleSubmit}
            >
              Delete Role
            </Button>
          </div>
        )}
      </Dialog>
    </>
  );
};

export default RolesEditButton;
