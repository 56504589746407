import React from "react";

export type RowsProps = {
  children: React.ReactNode;
};

export const Rows = (props: RowsProps) => (
  <div style={{ display: "flex", flexDirection: "column" }}>
    {props.children}
  </div>
);

export type RowProps = {
  children: React.ReactNode;
};

export const Row = (props: RowProps) => (
  <div
    style={{
      display: "flex",
      flexDirection: "row",
      alignItems: "stretch",
      gap: "1em",
    }}
  >
    {props.children}
  </div>
);
