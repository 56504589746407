import React from "react";

export const WaitCursor = () => {
  function capture(event: React.KeyboardEvent<HTMLDivElement>) {
    event.preventDefault();
  }

  return (
    <div
      onKeyDown={capture}
      onKeyUp={capture}
      style={{
        position: "fixed",
        top: 0,
        left: 0,
        bottom: 0,
        right: 0,
        cursor: "wait",
        zIndex: 1000,
      }}
    ></div>
  );
};
