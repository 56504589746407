import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { Resolver, useForm } from "react-hook-form";
import { Button, Card, Elevation, FormGroup, Intent } from "@blueprintjs/core";
import { getG4ApiError, GetTenantResponse } from "@microsearch/g4api-support";
import { subscribe } from "@microsearch/g4api-browser";

import "./HomePage.scss";
import { session } from "../..";
import { stage } from "../../version";
import { PageBanner } from "../../components/PageBanner";
import { StatusBar } from "../../components/StatusBar";
import { Row, Rows } from "../../components/Layout";

type G4Tenant = GetTenantResponse;

let unsubscribe: (() => void) | null = null;

export const HomePage = () => {
  const [tenants, setTenants] = useState<G4Tenant[]>([]);
  const [visible, setVisible] = useState(false);
  const [refresh, setRefresh] = useState(0);
  const navigate = useNavigate();

  useEffect(() => {
    (async () => {
      try {
        setTenants((await session.tenants.get()).data.tenants);
        setVisible(true);
        unsubscribe = subscribe({
          endpoint: stage,
          tenant: "<g4admin>",
          bearer: () => session.bearer!,
          onerror: (event: Event) => console.log("subscription error:", event),
          subs: {
            tenant: {
              create: (message) => setRefresh(refresh + 1),
              archive: (message) => setRefresh(refresh + 1),
            },
          },
        });
      } catch (error) {
        if (error instanceof Error) {
          session.disconnect();
          navigate("/");
        }
      }
    })();
    return cleanupSubscription;
  }, [navigate, refresh]);

  return (
    <div className="home-page">
      {visible && (
        <>
          <div style={{ display: "none" }}>{refresh}</div>
          <div className="banner">
            <PageBanner session={session} />
          </div>
          <div className="sidebar">
            <div className="tenant-list">
              <ul className="tenants-list">
                {session.claims.includes("g4admin:GET /admins") && (
                  <li key="Admins">
                    <div
                      className="microsearch-item"
                      title="MicroSearch Administrators"
                      onClick={() => navigate("/admins")}
                    >
                      <div className="tenant-name">
                        MicroSearch Administrators
                      </div>
                    </div>
                  </li>
                )}
                {tenants.map((t) => (
                  <li key={t.name}>
                    <div
                      className="tenant-item"
                      title={t.description!}
                      onClick={() => navigate(`/tenant/dashboard/${t.id}`)}
                    >
                      <div className="tenant-name">{t.name}</div>
                      <div className="tenant-description">
                        {t.description}&nbsp;
                      </div>
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
          <div className="controls">
            <Rows>
              <Row>
                {session.claims.includes("g4admin:POST /tenant") && (
                  <CreateTenantControl />
                )}
              </Row>
            </Rows>
          </div>
          <div className="status">
            <StatusBar />
          </div>
        </>
      )}
    </div>
  );
};

function cleanupSubscription() {
  if (unsubscribe) {
    unsubscribe();
    unsubscribe = null;
  }
}

type CreateTenantFormValues = {
  tenant: string;
  description: string;
};

const resolver: Resolver<CreateTenantFormValues> = async (values) => {
  return {
    values: values.tenant ? values : {},
    errors: !values.tenant
      ? {
          tenant: {
            type: "required",
            message: "Must provide a tenant name",
          },
        }
      : {},
  };
};

const CreateTenantControl = () => {
  const [error, setError] = useState<string | undefined>();
  const { register, handleSubmit } = useForm({ resolver });
  const navigate = useNavigate();

  const onSubmit = async (data: CreateTenantFormValues) => {
    const { tenant } = data;
    data.tenant = tenant.trim();
    if (data.tenant.length === 0) {
      setError("tenant name is required");
      return;
    }
    try {
      setError(undefined);
      const response = (
        await session.tenant.post({
          name: data.tenant,
          description: data.description,
        })
      ).data;
      navigate(`/tenant/dashboard/${response.id}`);
    } catch (error: unknown) {
      setError(getG4ApiError(error).message);
    }
  };

  return (
    <Card elevation={Elevation.TWO}>
      <form id="create-tenant-form" onSubmit={handleSubmit(onSubmit)}>
        <FormGroup label="Tenant Name:" inline>
          <input
            {...register("tenant")}
            autoFocus
            className="bp4-input bp4-intent-primary fillwidth"
          />
        </FormGroup>
        <FormGroup label="Description:" inline>
          <input
            {...register("description")}
            className="bp4-input bp4-intent-primary fillwidth"
          />
        </FormGroup>
        <ErrorDisplay error={error} />
        <Button type="submit" intent={Intent.PRIMARY}>
          Create Tenant
        </Button>
      </form>
    </Card>
  );
};

type ErrorDisplayProps = {
  error?: string;
};

const ErrorDisplay = (props: ErrorDisplayProps) => (
  <div className="error-display fillwidth">
    <p>{props.error}</p>
  </div>
);
