import { useEffect, useState } from "react";
import { Card, Elevation, HTMLTable } from "@blueprintjs/core";
import { useParams } from "react-router-dom";
import { GetTenantResponse } from "@microsearch/g4api-support";
import { subscribe } from "@microsearch/g4api-browser";

import "./TenantDashboardPage.scss";
import { session, showError } from "../..";
import { TenantPageLayout } from "../TenantPageLayout/TenantPageLayout";
import { Rows, Row } from "../../components/Layout";

import { stage } from "../../version";

type G4Tenant = GetTenantResponse;

let unsubscribe: (() => void) | null = null;

export const TenantDashboardPage = () => {
  const { tid } = useParams<{ tid: string }>();
  const [tenant, setTenant] = useState<G4Tenant | undefined>();

  useEffect(() => {
    (async () => {
      try {
        const response = (await session.tenant.get(+(tid as string))).data;
        setTenant(response);
        cleanupSubscription();
        const updateDashboard = async (message: unknown) =>
          setTenant((await session.tenant.get(+(tid as string))).data);
        unsubscribe = subscribe({
          endpoint: stage,
          tenant: response.name,
          bearer: () => session.bearer!,
          onerror: (event: Event) => console.log("subscription error:", event),
          subs: {
            auth: {
              auth: updateDashboard,
            },
            user: {
              create: updateDashboard,
              import: updateDashboard,
              update: updateDashboard,
              archive: updateDashboard,
            },
            session: {
              create: updateDashboard,
              fail: updateDashboard,
            },
          },
        });
      } catch (error) {
        if (error instanceof Error) showError(error.message);
      }
    })();
    return cleanupSubscription;
  }, [tid]);

  return (
    <>
      {tenant && (
        <TenantPageLayout tenant={tenant} selector="dashboard">
          <Rows>
            <Row>
              <Card elevation={Elevation.TWO}>
                <h3>Users</h3>
                <CountsTable header="User Status" values={tenant.activeUsers} />
                <h3>Archived Users</h3>
                <CountsTable
                  header="User Status"
                  values={tenant.archivedUsers}
                />
              </Card>
              <Card elevation={Elevation.TWO}>
                <h3>User Events </h3>
                <CountsTable header="Event Type" values={tenant.userEvents} />
              </Card>
            </Row>
          </Rows>
        </TenantPageLayout>
      )}
    </>
  );
};

function cleanupSubscription() {
  if (unsubscribe) {
    unsubscribe();
    unsubscribe = null;
  }
}

type CountsTableProps = {
  header: string;
  values: { [name: string]: number };
};

const CountsTable = (props: CountsTableProps) => {
  return (
    <HTMLTable className="data-table" condensed bordered striped>
      <thead>
        <tr>
          <td>{props.header}</td>
          <td>Count</td>
        </tr>
      </thead>
      <tbody>
        {Object.keys(props.values)
          .sort()
          .map((name, index) => (
            <tr key={index}>
              <td>{name.replace(/([A-Z])/g, " $1")}</td>
              <td className="data-table-numeric">
                {props.values[name].toLocaleString()}
              </td>
            </tr>
          ))}
      </tbody>
      <tfoot>
        <tr>
          <td></td>
          <td className="data-table-numeric">
            {Object.values(props.values)
              .reduce((acc, val) => acc + val, 0)
              .toLocaleString()}
          </td>
        </tr>
      </tfoot>
    </HTMLTable>
  );
};
