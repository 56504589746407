import { useState, useEffect } from "react";
import { HTMLTable, Dialog, Button, IconName } from "@blueprintjs/core";
import { GetTenantResponse, RoleResponse } from "@microsearch/g4api-support";
import "./RolesList.scss";
import { showError, session } from "..";
import RolesEditButton, {
  RolesAddButton,
  RolesDeleteButton,
  RolesViewTable,
} from "./RolesEditButton";

type G4Tenant = GetTenantResponse;
type G4Role = RoleResponse;

export type RolesListProps = {
  tenant: G4Tenant;
};

export const RolesList = (props: RolesListProps) => {
  const [roles, setRoles] = useState<G4Role[]>();
  const [selectedRole, setSelectedRole] = useState<G4Role | null>(null);

  const setSortedRoles = (roles: G4Role[]) =>
    setRoles(
      roles.sort(
        // order by scope, then by name
        (a, b) => {
          const sc = a.scope.localeCompare(b.scope);
          return sc !== 0 ? sc : a.name.localeCompare(b.name);
        }
      )
    );

  useEffect(() => {
    (async () => {
      try {
        const tenantSession = session.clone({ tenant: props.tenant.name });
        setSortedRoles((await tenantSession.roles.get()).data.roles);
      } catch (error) {
        if (error instanceof Error) showError(error.message);
      }
    })();
  }, [props.tenant]);

  const rolesEditProps = { tenant: props.tenant, setRoles: setSortedRoles };

  const getRoleViewButton = (role: G4Role, ind: number) => {
    const viewButtonProps = {
      icon: "eye-open" as IconName,
      className: "bp4-minimal",
    };
    return session.claims.includes("g4:GET /role/{id}") ? (
      <Button
        {...viewButtonProps}
        type={"button"}
        onClick={() => setSelectedRole(role)}
        key={`${role.name}-${ind}`}
      />
    ) : (
      <Button type={"button"} {...viewButtonProps} disabled={true} />
    );
  };

  const hasRoleClaim = (role: G4Role, claim: string) => {
    return (
      session.claims.includes(claim) && !["g4", "g4admin"].includes(role.scope)
    );
  };

  return (
    <>
      {roles && (
        <>
          <HTMLTable className="roles-data-table" condensed bordered striped>
            <thead>
              <tr>
                <td>Scope</td>
                <td>Role</td>
                <td>View</td>
                <td>Edit</td>
                <td>Delete</td>
              </tr>
            </thead>
            <tbody>
              {roles.map((role, ind) => (
                <tr key={`${role.name}-${ind}`}>
                  <td>{role.scope}</td>
                  <td>{role.name}</td>
                  <td>{getRoleViewButton(role, ind)}</td>
                  <td>
                    <RolesEditButton
                      {...rolesEditProps}
                      roles={roles}
                      role={role}
                      canUse={hasRoleClaim(role, `g4:PUT /role/{id}`)}
                    />
                  </td>
                  <td>
                    <RolesDeleteButton
                      {...rolesEditProps}
                      roles={roles}
                      role={role}
                      canUse={hasRoleClaim(role, `g4:DEL /role/{id}`)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </HTMLTable>
          <RolesAddButton
            {...rolesEditProps}
            roles={roles}
            canUse={session.claims.includes("g4:POST /role")}
          />

          <Dialog
            isOpen={selectedRole !== null}
            isCloseButtonShown={true}
            icon="badge"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            onClose={() => setSelectedRole(null)}
            title={`${selectedRole?.scope}:${selectedRole?.name}`}
            style={{ overflow: "auto", whiteSpace: "nowrap", width: "25vw" }}
          >
            {selectedRole !== null && (
              <RolesViewTable selectedRole={selectedRole} />
            )}
          </Dialog>
        </>
      )}
    </>
  );
};
