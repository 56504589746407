import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";

import { session } from "../..";
import { PageBanner } from "../../components/PageBanner";
import { Elevation, Card } from "@blueprintjs/core";
import "./LoginPage.scss";
import { StatusBar } from "../../components/StatusBar";

type ErrorProps = {
  error?: string;
};

const ValidationError = (props: ErrorProps) => (
  <div className="login-validation-error fillwidth">
    <p>{props.error}</p>
  </div>
);

export const LoginPage = () => {
  const [error, setError] = useState<string | null>(null);
  const navigate = useNavigate();
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  async function onSubmit(data: Record<string, string>) {
    try {
      setError(null);
      await session.connect(data.username, data.password);
      if (session.connected()) {
        navigate("/");
      } else {
        setError("Invalid username or password");
      }
    } catch (error) {
      if (error instanceof Error) {
        setError(error.message);
      }
    }
  }

  return (
    <div className="login-page">
      <div className="banner">
        <PageBanner />
      </div>
      <div className="login-page-card">
        <Card interactive={true} elevation={Elevation.TWO}>
          <form onSubmit={handleSubmit(onSubmit)}>
            <div>
              <p>
                <input
                  {...register("username", { required: true })}
                  className="bp4-input bp4-intent-primary bp4-large fillwidth"
                  placeholder="Username"
                  onFocus={() => setError(null)}
                  autoFocus
                />
              </p>
              <ValidationError
                error={errors.username && "Username is required"}
              />
            </div>
            <div>
              <p>
                <input
                  {...register("password", { required: true })}
                  className="bp4-input bp4-intent-primary bp4-large fillwidth"
                  placeholder="Password"
                  onFocus={() => setError(null)}
                  type="password"
                />
              </p>
              <ValidationError
                error={errors.password && "Password is required"}
              />
            </div>
            <div>
              <p>
                <button
                  className="bp4-button bp4-intent-primary bp4-large fillwidth"
                  type="submit"
                >
                  Login
                </button>
              </p>
            </div>
            <ValidationError error={error ?? ""} />
          </form>
        </Card>
      </div>
      <div className="status">
        <StatusBar />
      </div>
    </div>
  );
};
