import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import {
  HTMLTable,
  Dialog,
  Classes,
  Button,
  Intent,
  FormGroup,
  ResizeSensor,
} from "@blueprintjs/core";
import {
  GetTenantResponse,
  GetUserDetailsResponse,
  UserWithAppMetadata,
  getG4ApiError,
} from "@microsearch/g4api-support";
import { G4UserStatus, subscribe } from "@microsearch/g4api-browser";

import "./TenantUsersPage.scss";
import { session, showError, showSuccess } from "../..";
import { DetailEntry } from "../../components/DetailEntry";
import { TenantPageLayout } from "../TenantPageLayout/TenantPageLayout";
import { WaitCursor } from "../../components/WaitCursor";
import { stage } from "../../version";

const LOADING_SIZE = 1000;

type G4Tenant = GetTenantResponse;
type G4User = UserWithAppMetadata;
type G4UserDetail = GetUserDetailsResponse;

type SearchTerms = {
  user: string;
  archived: boolean;
};

type UserList = {
  usersLoaded: number;
  users: G4User[];
};

export const TenantUsersPage = () => {
  const { tid } = useParams<{ tid: string }>();
  const navigate = useNavigate();
  const [tenant, setTenant] = useState<G4Tenant | null>(null);
  const [selectedUser, setSelectedUser] = useState<G4UserDetail | null>(null);
  const [loading, setLoading] = useState(false);
  const [totalUsers, setTotalUsers] = useState(0);
  const [userList, setUserList] = useState<UserList>({
    usersLoaded: 0,
    users: [],
  });
  const [searchBarHeight, setSearchBarHeight] = useState(0);
  const [searchTerms, setSearchTerms] = useState<SearchTerms>({
    user: "",
    archived: false,
  });
  const userRef = useRef<HTMLInputElement>(null);
  let archivedRef = useRef<HTMLInputElement>(null);

  useEffect(() => {
    loadUsers(0);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant, userList]);

  useEffect(() => {
    let unsubscribe: (() => void) | null;
    (async () => {
      let tenantInfo: GetTenantResponse;
      try {
        tenantInfo = (await session.tenant.get(+(tid as string))).data;
        setTenant(tenantInfo);
      } catch (error: unknown) {
        showError(getG4ApiError(error).message);
        return;
      }
      unsubscribe = subscribe({
        endpoint: stage,
        tenant: tenantInfo.name,
        bearer: () => session.bearer!,
        onerror: (event: Event) => console.log("subscription error:", event),
        subs: {
          user: {
            create: (message) => showSuccess(`new user: ${message.username}`),
            import: (message) => showSuccess(`new user: ${message.username}`),
            update: (message) =>
              showSuccess(`updated user: ${message.username}`),
            archive: (message) => showSuccess(`archived user: ${message.id}`),
          },
        },
      });
    })();

    return () => {
      if (unsubscribe) {
        unsubscribe();
        unsubscribe = null;
      }
    };
  }, [tid]);

  function loadUsers(startIndex: number) {
    (async () => {
      try {
        await loadUsersAsync(startIndex);
      } catch (error) {
        if (error instanceof Error) showError(error.message);
      }
    })();
  }

  async function getUser(id: number): Promise<G4UserDetail | null> {
    try {
      const tenantSession = session.clone({ tenant: tenant?.name });
      return (await tenantSession.user.get(id)).data;
    } catch (error) {
      if (error instanceof Error) {
        showError(error.message);
      }
      return null;
    }
  }

  async function loadUsersAsync(startIndex: number) {
    if (tenant && !loading) {
      try {
        setLoading(true);
        const tenantSession = session.clone({ tenant: tenant?.name });
        const response = (
          await tenantSession.users.get({
            contains: searchTerms.user,
            archived: searchTerms.archived,
            skip: startIndex,
            take: LOADING_SIZE,
          })
        ).data;
        if (startIndex === userList.usersLoaded) {
          const users = [...userList.users, ...response.users];
          setUserList({ users: users, usersLoaded: users.length });
        }
        setTotalUsers(response.total);
      } catch (error) {
        if (error instanceof Error) showError(error.message);
      } finally {
        setLoading(false);
      }
    }
  }

  function search() {
    const user = userRef.current!.value;
    const archived = archivedRef.current!.checked;
    setSearchTerms({ user, archived });
    setUserList({ users: [], usersLoaded: 0 });
  }

  return (
    <>
      {loading && <WaitCursor />}
      {tenant && (
        <TenantPageLayout tenant={tenant} selector="users">
          <ResizeSensor
            onResize={(entries) =>
              setSearchBarHeight(entries[0].contentRect.height)
            }
          >
            <div className="user-search-bar">
              <FormGroup inline>
                <div className="bp4-input-group">
                  <span className="bp4-icon bp4-icon-search"></span>
                  <input
                    className="bp4-input bp4-intent-primary"
                    id="user-search"
                    name="user-search"
                    type="search"
                    dir="auto"
                    autoFocus
                    ref={userRef}
                    onChange={search}
                  />
                </div>
              </FormGroup>
              <FormGroup inline>
                <label className="bp4-control bp4-checkbox .modifier">
                  <input
                    id="archived"
                    name="archived"
                    type="checkbox"
                    ref={archivedRef}
                    onChange={search}
                  />
                  <span className="bp4-control-indicator"></span>
                  Archived
                </label>
              </FormGroup>
            </div>
          </ResizeSensor>
          <UserManagerHeader />
          <AutoSizer>
            {(size) => (
              <FixedSizeList
                className="user-manager"
                height={size.height - (searchBarHeight + 25)}
                width={size.width}
                itemCount={userList.usersLoaded}
                itemSize={25}
                onItemsRendered={(item) => {
                  if (
                    userList.usersLoaded < totalUsers &&
                    item.visibleStopIndex >=
                      userList.usersLoaded - LOADING_SIZE / 2
                  ) {
                    loadUsers(userList.usersLoaded);
                  }
                }}
              >
                {({ index, style }) => {
                  const user = userList.users[index];
                  return (
                    <UserManagerItem
                      index={index}
                      archived={searchTerms.archived}
                      style={style}
                      user={user}
                      click={async () => {
                        setSelectedUser(
                          await getUser(userList.users[index].id)
                        );
                      }}
                    />
                  );
                }}
              </FixedSizeList>
            )}
          </AutoSizer>

          <Dialog
            isOpen={selectedUser !== null}
            icon="person"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            title={selectedUser?.fullname ?? ""}
            onClose={() => setSelectedUser(null)}
            style={{ overflow: "auto", whiteSpace: "nowrap", width: "50vw" }}
          >
            {selectedUser && <UserDetails user={selectedUser} />}

            <div className={Classes.DIALOG_FOOTER}>
              <div className={Classes.DIALOG_FOOTER_ACTIONS}>
                <Button
                  intent={Intent.PRIMARY}
                  onClick={() => {
                    navigate(
                      `/tenant/user-events/${tenant.id}/${selectedUser?.id}`
                    );
                  }}
                >
                  View User Events
                </Button>
              </div>
            </div>
          </Dialog>
        </TenantPageLayout>
      )}
    </>
  );
};

const UserManagerHeader = () => (
  <div className="user-manager-header">
    <div className="user-manager-index" />
    <div className="user-manager-fullname">User</div>
    <div className="user-manager-username">Username</div>
    <div className="user-manager-email">Email</div>
    <div className="user-manager-status">Status</div>
    <div className="user-manager-lastseen">Last Login</div>
  </div>
);

type UserManagerItemProps = {
  index: number;
  archived: boolean;
  user?: G4User | null;
  style?: React.CSSProperties;
  click: () => void;
};

const UserManagerItem = (props: UserManagerItemProps) => (
  <>
    {props.user && (
      <div
        className={
          props.archived ? "user-manager-row-archived" : "user-manager-row"
        }
        style={props.style}
        onClick={() => !props.archived && props.click()}
      >
        <div className="user-manager-index">{props.index.toLocaleString()}</div>
        <div className="user-manager-fullname">{props.user.fullname}</div>
        <div className="user-manager-username">
          {props.user.status !== G4UserStatus.Anonymous && props.user.username}
        </div>
        <div className="user-manager-email">{props.user.email}</div>
        <div className="user-manager-status">
          {G4UserStatus[props.user.status]}
        </div>
        <div className="user-manager-lastseen">
          {props.user.lastSeen &&
            new Date(props.user.lastSeen).toLocaleDateString()}
        </div>
      </div>
    )}
  </>
);

const UserDetails = (props: { user: G4UserDetail }) => (
  <HTMLTable bordered={true} condensed={true}>
    <tbody>
      <DetailEntry name="Id" value={props.user.id} />
      <DetailEntry
        name="Username"
        value={
          props.user.status === G4UserStatus.Anonymous
            ? ""
            : props.user.username
        }
      />
      <DetailEntry name="Status" value={G4UserStatus[props.user.status]} />
      <DetailEntry name="Email" value={props.user.email} />
      <DetailEntry name="Roles" value={props.user.roleNames.join("\n")} />
      <DetailEntry name="Profiles" value={props.user.profileNames.join("\n")} />
      {props.user.metadata &&
        Object.keys(props.user.metadata).map((key) => (
          <DetailEntry
            name={`metadata \u2192 ${key}`}
            value={JSON.stringify(props.user.metadata![key], null, 2)}
          />
        ))}
    </tbody>
  </HTMLTable>
);
