import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import AutoSizer from "react-virtualized-auto-sizer";
import { FixedSizeList } from "react-window";
import { Dialog, HTMLTable } from "@blueprintjs/core";
import {
  GetTenantResponse,
  RetrievedUserEvent,
} from "@microsearch/g4api-support";
import { G4UserEventType, G4UserStatus } from "@microsearch/g4api-browser";

import "./TenantUserEventsPage.scss";
import { session, showError } from "../..";
import { DetailEntry } from "../../components/DetailEntry";
import { TenantPageLayout } from "../TenantPageLayout/TenantPageLayout";

const LOADING_SIZE = 1000;

type G4Tenant = GetTenantResponse;
type UserEvent = RetrievedUserEvent;

export const TenantUserEventsPage = () => {
  const { tid, uid } = useParams<{ tid: string; uid?: string }>();
  const [tenant, setTenant] = useState<G4Tenant | null>(null);
  const [detailEvent, setDetailEvent] = useState<UserEvent | null>(null);
  const [loading, setLoading] = useState(false);
  const [eventsLoaded, setEventsLoaded] = useState(0);
  const [totalEvents, setTotalEvents] = useState(0);
  const [events] = useState<UserEvent[]>([]);

  useEffect(() => {
    loadEvents(0);
    //  eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tenant]);

  useEffect(() => {
    (async () => {
      try {
        setTenant((await session.tenant.get(+(tid as string))).data);
      } catch (error) {
        if (error instanceof Error) showError(error.message);
      }
    })();
  }, [tid]);

  function loadEvents(startIndex: number) {
    (async () => {
      await loadEventsAsync(startIndex);
    })();
  }

  async function loadEventsAsync(startIndex: number) {
    if (tenant && !loading) {
      try {
        setLoading(true);
        const tenantSession = session.clone({ tenant: tenant.name });
        const response = (
          await tenantSession.userEvents.post({
            userId: uid ? +uid : null,
            skip: startIndex,
            take: LOADING_SIZE,
          })
        ).data;
        if (startIndex === eventsLoaded) {
          for (const event of response.events) {
            events.push(event);
          }
          setEventsLoaded(events.length);
        }
        setTotalEvents(response.total);
      } catch (error) {
        if (error instanceof Error) {
          showError(error.message);
        }
      } finally {
        setLoading(false);
      }
    }
  }

  return (
    <>
      {tenant && (
        <TenantPageLayout tenant={tenant} selector="user-events">
          <UserEventLogHeader />
          <AutoSizer>
            {(size) => (
              <FixedSizeList
                className="user-event-log"
                height={size.height - 25}
                width={size.width}
                itemCount={eventsLoaded}
                itemSize={25}
                onItemsRendered={(item) => {
                  if (
                    eventsLoaded < totalEvents &&
                    item.visibleStopIndex >= eventsLoaded - LOADING_SIZE / 2
                  ) {
                    loadEvents(eventsLoaded);
                  }
                }}
              >
                {({ index, style }) => {
                  const event = events[index];
                  return (
                    <UserEventLogItem
                      index={index}
                      style={style}
                      event={event}
                      click={() => {
                        setDetailEvent(event);
                      }}
                    />
                  );
                }}
              </FixedSizeList>
            )}
          </AutoSizer>

          <Dialog
            isOpen={detailEvent !== null}
            icon="timeline-events"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            title={timestamp(detailEvent?.occurred)}
            onClose={() => setDetailEvent(null)}
            style={{ overflow: "auto", whiteSpace: "nowrap", width: "50vw" }}
          >
            {detailEvent && <EventDetails event={detailEvent} />}
          </Dialog>
        </TenantPageLayout>
      )}
    </>
  );
};

function timestamp(occurred?: string): string {
  if (!occurred) return "";
  const date = new Date(occurred + "Z");
  return `${date.toLocaleDateString()} ${date.toLocaleTimeString()} (${occurred.replace(
    /\..*/,
    " UTC"
  )})`;
}

const UserEventLogHeader = () => (
  <div className="user-log-event-header">
    <div className="user-log-event-index" />
    <div className="user-log-event-timestamp">Time Stamp</div>
    <div className="user-log-event-type">Event Type</div>
    <div className="user-log-event-username">User</div>
    <div className="user-log-event-username">Active User</div>
    <div className="user-log-event-host">Host</div>
    <div className="user-log-event-application">Application</div>
  </div>
);

type UserEventLogItemProps = {
  index: number;
  event?: UserEvent | null;
  style?: React.CSSProperties;
  click: () => void;
};

const UserEventLogItem = (props: UserEventLogItemProps) =>
  props.event ? (
    <div
      className="user-log-event-row"
      style={props.style}
      onClick={() => props.click()}
    >
      <div className="user-log-event-index">{props.index.toLocaleString()}</div>
      <div className="user-log-event-timestamp">
        {timestamp(props.event.occurred)}
      </div>
      <div className="user-log-event-type">
        {G4UserEventType[props.event.eventType]
          .replace(/([A-Z])/g, " $1")
          .substring(1)}
      </div>
      <div className="user-log-event-username">
        {DisplayUserFromEvent(props.event)}
      </div>
      <div className="user-log-event-username">
        {DisplayActiveUserFromEvent(props.event)}
      </div>
      <div className="user-log-event-host">{props.event.host}</div>
      <div className="user-log-event-application">{props.event.appName}</div>
    </div>
  ) : (
    <></>
  );

const EventDetails = (props: { event: UserEvent }) => (
  <HTMLTable bordered={true} condensed={true}>
    <tbody>
      <DetailEntry
        name="Event Type"
        value={G4UserEventType[props.event.eventType]
          .replace(/([A-Z])/g, " $1")
          .substring(1)}
      />
      <DetailEntry name="User" value={DisplayUserFromEvent(props.event)} />
      <DetailEntry
        name="Active User"
        value={DisplayActiveUserFromEvent(props.event)}
      />
      <DetailEntry name="Host" value={props.event.host} />
      <DetailEntry name="Application" value={props.event.appName} />
      {props.event.detail && (
        <DetailEntry
          name="Detail"
          value={JSON.stringify(props.event.detail, null, 2)}
        />
      )}
    </tbody>
  </HTMLTable>
);

function DisplayUserFromEvent(event: UserEvent): string {
  return event.status === G4UserStatus.Anonymous
    ? event.fullname
    : `${event.fullname} (${event.username})`;
}

function DisplayActiveUserFromEvent(event: UserEvent): string {
  return event.activeUsername === null
    ? ""
    : `${event.activeFullname} (${event.activeUsername})`;
}
