import {
  Button,
  Checkbox,
  FormGroup,
  HTMLSelect,
  Intent,
} from "@blueprintjs/core";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import React, { ChangeEvent, FormEvent, useState } from "react";
import { showError } from "../index";
import { RoleResponse } from "@microsearch/g4api-support";

type G4Role = RoleResponse;

type useRolesFormProps = {
  sendRoleData: (data: RoleFormValues) => Promise<G4Role>;
  setModifyingRole: (addingRole: boolean) => void;
  setRoles: (roles: G4Role[]) => void;
  selectedClaims: string[];
  setSelectedClaims: (selectedClaims: string[]) => void;
  claimList: string[];
  scopeList: string[];
  roles: G4Role[];
};

type useRolesFormType = {
  selectedClaims: string[];
  handleBoxCheck: (ev: FormEvent<HTMLInputElement>) => void;
  onSubmit: (data: RoleFormValues) => void;
};

interface RoleFormProps extends useRolesFormProps {
  defaultValues: RoleFormValues;
  role?: G4Role;
}

export type RoleFormValues = {
  roleName: string;
  defaultScope: string;
  claims: { name: string; scope: string }[];
  id?: number;
};

const useRolesForm = ({
  sendRoleData,
  setModifyingRole,
  setRoles,
  roles,
  setSelectedClaims,
  selectedClaims,
}: useRolesFormProps): useRolesFormType => {
  const handleBoxCheck = (ev: FormEvent<HTMLInputElement>) => {
    const target = ev.target as HTMLInputElement;
    if (target.checked) {
      setSelectedClaims([target.value, ...selectedClaims]);
    } else {
      setSelectedClaims(
        selectedClaims.filter((claim) => !(claim === target.value))
      );
    }
  };

  const onSubmit = (data: RoleFormValues) => {
    sendRoleData(data)
      .then((newRole) => {
        setModifyingRole(false);
        setRoles([
          newRole,
          ...roles.filter((thisRole) => thisRole.id !== newRole.id),
        ]);
      })
      .catch((error) => {
        if (error instanceof Error) showError(error.message);
      });
  };

  return { selectedClaims, handleBoxCheck, onSubmit };
};

const RoleForm = ({
  role,
  selectedClaims,
  scopeList,
  defaultValues,
  ...restProps
}: RoleFormProps) => {
  const { onSubmit, handleBoxCheck } = useRolesForm({
    selectedClaims,
    scopeList,
    ...restProps,
  });
  const { register, handleSubmit, control } = useForm({ defaultValues });
  const { fields: claimFields } = useFieldArray({ control, name: "claims" });
  const [scopeFilter, setScopeFilter] = useState<string>("noFilter");

  const onChangeScopeFilter = (ev: ChangeEvent<HTMLSelectElement>): void => {
    setScopeFilter(ev.currentTarget.value);
  };

  return (
    <form id={"create-role-form"} onSubmit={handleSubmit(onSubmit)}>
      <FormGroup label={"Role Name:"}>
        <input
          {...register("roleName")}
          autoFocus
          className="bp4-input bp4-intent-primary fillwidth"
        />
      </FormGroup>
      <FormGroup label={"Scope:"}>
        <Controller
          control={control}
          name={"defaultScope"}
          render={({ field }) => (
            <HTMLSelect
              {...field}
              autoFocus
              className="bp4-input bp4-intent-primary fillwidth"
              disabled={role !== undefined}
            >
              {scopeList
                .filter((scope) => scope !== "g4")
                .map((scope) => (
                  <option value={scope} key={`scope-${scope}`}>
                    {scope}
                  </option>
                ))}
            </HTMLSelect>
          )}
        />
      </FormGroup>
      <FormGroup label={"Claim Filter(Scope):"}>
        <HTMLSelect
          autoFocus
          className="bp4-input bp4-intent-primary fillwidth"
          onChange={onChangeScopeFilter}
          defaultValue={scopeFilter}
        >
          <option value={"noFilter"}> -- </option>
          {scopeList.map((scope) => (
            <option value={scope} key={`scope-filter-${scope}`}>
              {scope}
            </option>
          ))}
        </HTMLSelect>
      </FormGroup>
      <FormGroup>
        {claimFields.map((item, index) => (
          <Controller
            control={control}
            key={item.id}
            name={`claims.${index}`}
            render={({ field }) => {
              const { value, onChange, ...restFieldProps } = field;
              const fieldProps = {
                label: item.name,
                value: value.name,
                onChange: handleBoxCheck,
                checked: selectedClaims.includes(item.name),
                ...restFieldProps,
              };
              return scopeFilter === value.scope ||
                scopeFilter === "noFilter" ? (
                <Checkbox {...fieldProps} />
              ) : (
                <></>
              );
            }}
          />
        ))}
      </FormGroup>
      <Button type="submit" intent={Intent.PRIMARY}>
        {role ? "Update" : "Create"} Role
      </Button>
    </form>
  );
};

export default RoleForm;
