import { useState, useEffect } from "react";
import { HTMLTable, Dialog } from "@blueprintjs/core";
import {
  GetProfileResponse,
  GetTenantResponse,
} from "@microsearch/g4api-support";

import "./ProfilesList.scss";
import { showError, session } from "..";

type G4Tenant = GetTenantResponse;
type G4Profile = GetProfileResponse;

export type ProfilesListProps = {
  tenant: G4Tenant;
};

export const ProfilesList = (props: ProfilesListProps) => {
  const [profiles, setProfiles] = useState<G4Profile[]>();
  const [selectedProfile, setSelectedProfile] = useState<G4Profile | null>(
    null
  );

  useEffect(() => {
    (async () => {
      try {
        const tenantSession = session.clone({ tenant: props.tenant.name });
        setProfiles(
          (await tenantSession.profiles.get()).data.profiles.sort((a, b) =>
            a.name.localeCompare(b.name)
          )
        );
      } catch (error) {
        if (error instanceof Error) showError(error.message);
      }
    })();
  }, [props.tenant]);

  return (
    <>
      {profiles && (
        <>
          <HTMLTable className="profiles-data-table" condensed bordered striped>
            <thead>
              <tr>
                <td>Profile</td>
              </tr>
            </thead>
            <tbody>
              {profiles.map((role) => (
                <tr onClick={() => setSelectedProfile(role)}>
                  <td>{role.name}</td>
                </tr>
              ))}
            </tbody>
          </HTMLTable>

          <Dialog
            isOpen={selectedProfile !== null}
            isCloseButtonShown={true}
            icon="mugshot"
            canEscapeKeyClose={true}
            canOutsideClickClose={true}
            onClose={() => setSelectedProfile(null)}
            title={selectedProfile?.name}
            style={{ overflow: "auto", whiteSpace: "nowrap", width: "25vw" }}
          >
            {selectedProfile !== null && <div>profile details go here</div>}
          </Dialog>
        </>
      )}
    </>
  );
};
